import actionTypes from "./Cart.types";

const initialState = {
  fullScreenLoader: false,
  cartItems: [],
  // shippingInfo: { country: "Pakistan", province: "Sindh", city: "Karachi", zipCode: "12345", address: "Home Sweet Home", additionalNotes: "Deliver ASAP", },
  // shippingUser: { name: "User", email: "user@pintar.pk", phoneNo: "0332 1234567" }
  shippingInfo: { country: "", province: "", city: "", zipCode: "", address: "", additionalNotes: "", },
  shippingUser: { name: "", email: "", phoneNo: "" },
  shippingCostInfo: {
    totalWeight: 0,
    discountCode: {},
    cost: 0
  },
  cities: {
    'Punjab': [],
    'Sindh': [],
    'Khyber Pakhtunkhwa': [],
    'Islamabad': [],
    'Balochistan': [],
    'Azad Kashmir': [],
    'Gilgit-Baltistan': [],
  },
  citiesUpdatedAt: ''
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_CITIES:
      return { ...state, cities: payload.cities, citiesUpdatedAt: payload.updatedAt };
    case actionTypes.SET_SHIPPING_COST:
      return { ...state, shippingCostInfo: { ...state.shippingCostInfo, ...payload } };
    case actionTypes.SET_ITEMS:
      return { ...state, cartItems: payload };
    case actionTypes.ADD_ITEM:
      localStorage.setItem("pintar-cart-items", JSON.stringify(state.cartItems.concat(payload)))
      return { ...state, cartItems: state.cartItems.concat(payload) };
    case actionTypes.REMOVE_ITEM:
      return { ...state, cartItems: state.cartItems.filter(i => !(i.id === payload.id && i.hex === payload.hex && i.type.id === payload.type)) };
    case actionTypes.INCREASE_ITEM_QUANTITY:
      return {
        ...state, cartItems: state.cartItems.map(i => i.id === payload.id && i.hex === payload.hex && i.type.id === payload.type
          ? { ...i, quantity: +i.quantity + 1 }
          : i)
      };
    case actionTypes.DECREASE_ITEM_QUANTITY:
      return {
        ...state, cartItems: state.cartItems.map(i => i.id === payload.id && i.hex === payload.hex && i.type.id === payload.type
          ? { ...i, quantity: i.quantity - 1 }
          : i)
      };
    case actionTypes.CLEAR_CART:
      return { ...state, cartItems: [] };
    case actionTypes.CLEAR_CITIES:
      return { ...state, cities: initialState.cities, shippingCostInfo: initialState.shippingCostInfo };
    case actionTypes.SET_SHIPPING_INFO:
      return { ...state, shippingInfo: payload };
    case actionTypes.SET_SHIPPING_USER:
      return { ...state, shippingUser: { ...state.shippingUser, ...payload } };
    default:
      return state;
  }
};

export default reducer;

import actionTypes from "./Product.types";

const initialState = {
  productDetails: {},
  productExtraInfo: {},
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          [payload.id]: payload
        }
      };
    case actionTypes.SET_PRODUCT_EXTRA_INFO:
      return {
        ...state,
        productExtraInfo: {
          ...state.productExtraInfo,
          [payload.key]: payload.info,
        },
      };
    case actionTypes.SET_PROUDCT_LIKE:
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          [payload.id]: {
            ...state.productDetails[payload.id],
            liked: payload.liked,
          }
        },
      }
    default:
      return state;
  }
};

export default reducer;

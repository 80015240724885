import React from 'react';
import { useSelector } from 'react-redux';
import Spinner from '../Spinner/Spinner';
import './FullScreenLoader.css';

const FullScreenLoader = () => {
  const show = useSelector(state => state.auth.fullScreenLoader);

  if (!show) return null;

  return <div className={'spinner-container'}> <Spinner /></div>;
};

export default FullScreenLoader;
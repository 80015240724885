import firebase from "firebase/app";
import "firebase/auth";
import { setShippingUser } from "../../containers/CartPage/Cart.action";
import request from "../../shared/request";
import showToast from "../../shared/showToast";
import { setUser, setFullScreenLoader } from "./Auth.action";

export const register = (payload, onClose) => async (dispatch) => {
  try {
    dispatch(setFullScreenLoader(true));
    const userCredential = await firebase
      .auth()
      .createUserWithEmailAndPassword(payload.email, payload.password);
    delete payload.password;
    payload.id = userCredential.user.uid;
    const [userResponse, idToken] = await Promise.all([
      request("/user/register", "post", null, payload),
      firebase.auth().currentUser.getIdToken(true),
    ]);
    localStorage.setItem("pintar-token", idToken);
    localStorage.setItem("pintar-refresh-token", userCredential.user.refreshToken);
    dispatch(setUser(userResponse.data.data.user));
    dispatch(setShippingUser(userResponse.data.data.user));
    onClose();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    showToast("Error", e.message ? e.message : "No Network Available");
  } finally {
    dispatch(setFullScreenLoader(false));
  }
};

export const login = (payload, setLoading, onClose) => async (dispatch) => {
  try {
    dispatch(setFullScreenLoader(true));
    const user = await firebase
      .auth()
      .signInWithEmailAndPassword(payload.email, payload.password);
    const idToken = await firebase.auth().currentUser.getIdToken(true);
    localStorage.setItem("pintar-token", idToken);
    localStorage.setItem("pintar-refresh-token", user.user.refreshToken);
    dispatch(verifyMe(setLoading, onClose));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    setLoading(false);
    dispatch(setFullScreenLoader(false));
    showToast("Error", e.message ? e.message : "No Network Available");
  }
};

export const verifyMe = (setLoading, onClose) => async (dispatch) => {
  try {
    const res = await request("/user/me", "get", true);
    dispatch(setUser(res.data.data.user));
    dispatch(setShippingUser(res.data.data.user));
    if (setLoading) onClose();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    if (setLoading) setLoading();
    dispatch(logout());
  } finally {
    dispatch(setFullScreenLoader(false));
  }
};

export const updateUserProfile = (setEditing, payload) => async (dispatch) => {
  try {
    dispatch(setFullScreenLoader(true));
    const res = await request("/user/update", "post", true, payload);
    dispatch(setUser(res.data.data.user));
    dispatch(setShippingUser(res.data.data.user));
    setEditing(false);
    showToast("Success", "User details updated successfully");
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    showToast("Error", "Unable to update details");
  } finally {
    dispatch(setFullScreenLoader(false));
  }
};

export const changeUserPassword = (payload, onSuccess) => async (dispatch) => {
  firebase.auth().onAuthStateChanged(async user => {
    try {
      dispatch(setFullScreenLoader(true));
      const cred = firebase
        .auth
        .EmailAuthProvider
        .credential(payload.email, payload.currentPassword);
      await user.reauthenticateWithCredential(cred); // first it will reauthenticate fresh
      await user.updatePassword(payload.newPassword);
      showToast("Success", "Password updated successfully");
      onSuccess();
    } catch (e) {
      console.log(e);
      let msg = "";
      switch (e.code) {
        case "auth/wrong-password":
          msg = "Current password is not correct";
          break;
        case "auth/weak-password":
          msg = "New password must contain at least 6 characters";
          break;
        default:
          msg = "Something went wrong";
          break;
      }
      showToast("Error", msg);
    } finally {
      dispatch(setFullScreenLoader(false));
    }
  });
};

export const logout = () => (dispatch) => {
  dispatch({ type: "LOGOUT" });
  dispatch(setShippingUser({ name: "", email: "", phoneNo: "" }));
  localStorage.removeItem("pintar-token");
  localStorage.removeItem('pintar-refresh-token');
};

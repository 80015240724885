import actionTypes from "./Orders.types";

const initialState = {
  orders: {
    delivered: [],
    cancelled: [],
    processing: [],
  },
  pendingPaymentOrders: [],
  selectedOrder: null
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_ORDERS:
      return { ...state, orders: payload };
    case actionTypes.SET_SELECTED_ORDER:
      return { ...state, selectedOrder: payload };
    case actionTypes.ADD_ORDER:
      if (state.orders.processing.find(o => o.id === payload.id)) return state;
      if (state.orders.cancelled.find(o => o.id === payload.id)) return state;
      return {
        ...state,
        orders: {
          ...state.orders,
          processing: [payload, ...state.orders.processing]
        }
      };
    case actionTypes.ORDER_CANCELED:
      return {
        ...state,
        orders: {
          ...state.orders,
          processing: state.orders.processing.filter(o => o.id !== payload.id),
          cancelled: [{ ...payload }, ...state.orders.cancelled],
        },
        selectedOrder: { ...payload }
      };
    case actionTypes.ADD_PENDING_PAYMENT:
      return {
        ...state,
        pendingPaymentOrders: [...(state.pendingPaymentOrders || []), payload]
      };
    case actionTypes.REMOVE_PENDING_PAYMENT:
      return {
        ...state,
        pendingPaymentOrders: state.pendingPaymentOrders.filter(p => p !== payload)
      };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
};

export default reducer;

import React from "react";
import { toast } from "react-toastify";
import { Close } from "@material-ui/icons";
import SuccessIcon from "../assets/Alerts/success.svg";
import ErrorInfo from "../assets/Alerts/info_error.svg";
import Congrats from "../assets/Alerts/congrats.svg";

const showToast = (type, text, autoCloseFlag, toastId) => {
  let icon = null;
  let color = "";
  let bgColor = "";
  let borderColor = "";
  switch (type) {
    case "Success":
      icon = SuccessIcon;
      color = "#3FBE62";
      bgColor = "#DEF7E4";
      borderColor = "#95CCA3";
      break;
    case "Info":
      icon = ErrorInfo;
      color = "#d5ac4f";
      bgColor = "rgb(251 235 198)";

      borderColor = "#d3ac4f";
      break;
    case "Error":
      icon = ErrorInfo;
      color = "#EB4E2C";
      bgColor = "#FCEDEA";
      borderColor = "#D9A59A";
      break;
    case "Congrats":
      icon = Congrats;
      color = "#EB4E2C";
      bgColor = "#fff";
      borderColor = "#0000001A";
      break;
    default:
  }

  let closeButton = false;
  let autoClose = false;
  if (autoCloseFlag) {
    closeButton = false;
    autoClose = true;
  }

  toast(
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {type === "Congrats" ? (
        <div
          style={{
            height: 40,
            width: 40,

            borderRadius: 13,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "1rem",
          }}
        >
          <img src={icon} alt="" />
        </div>
      ) : (
        <div
          style={{
            height: 40,
            width: 40,
            backgroundColor: color,
            borderRadius: 13,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "1rem",
          }}
        >
          {type === "Error" ? (
            <img
              alt=""
              src={icon}
              style={{
                transform: "rotate(180deg)",
              }}
            />
          ) : (
            <img src={icon} alt="" />
          )}
        </div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flex: 1,
        }}
      >
        <div
          style={{
            font: "400 1.4rem/2.1rem Poppins",
            color: "#000000",
            flex: 1,
            marginRight: "2rem",
          }}
        >
          {text}
        </div>
        <Close style={{ width: 24, height: 24 }} />
      </div>
    </div>,
    {
      autoClose,
      closeButton,
      toastId,
      style: {
        backgroundColor: bgColor,
        padding: 10,
        borderRadius: 15,
        borderColor,
        borderStyle: "solid",
        display: "flex",
      },
      bodyStyle: {
        padding: 0,
        width: "100%",
      },
    }
  );
};

export default showToast;

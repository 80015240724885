import actionTypes from "./Blogs.types";

const initialState = {
  blogs: [],
  blogsUpdatedAt: '',
  topics: [],
  blogTopicsUpdatedAt: '',
  popularProducts: {},
  blog: {},
  fetched: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_BLOGS:
      return { ...state, blogs: payload.blogs, blogsUpdatedAt: payload.blogsUpdatedAt, fetched: true, };
    case actionTypes.SET_BLOG:
      return { ...state, blog: { ...state.blog, [payload.id]: payload } };
    case actionTypes.SET_POPULAR_PRODUCTS:
      return { ...state, popularProducts: payload };
    case actionTypes.SET_TOPICS:
      return { ...state, topics: payload.blogTopics, blogTopicsUpdatedAt: payload.blogTopicsUpdatedAt, };
    default:
      return state;
  }
};

export default reducer;

import actionTypes from "./Cart.types";
import request from "../../shared/request";
import { setCities, setShippingCost } from "./Cart.action";
import { setFullScreenLoader } from "../../components/AuthModal/Auth.action";
import showToast from "../../shared/showToast";

export const clearCart = payload => dispatch => {
  localStorage.removeItem("pintar-cart-items");
  dispatch({
    payload,
    type: actionTypes.CLEAR_CART,
  });
};

export const clearCitites = payload => dispatch => {
  localStorage.removeItem("pintar-cart-shipping-cost");
  dispatch({
    payload,
    type: actionTypes.CLEAR_CITIES,
  });
  dispatch(getCities(true));
};

export const verifyCode = code => async dispatch => {
  if(!code || !code.trim()) return;
  dispatch(setFullScreenLoader(true));
  try {
    const res = await request(`/discount/${code}`, "get", true);
    console.log(res)
    dispatch(setShippingCost({ discountCode: res.data.data.discount }));
    showToast("Success", "Code applied successfully")
  } catch (e) {
    console.log(e);
    showToast("Error", e.response ? e.response.data.message : "Something went wrong");
    dispatch(setShippingCost({ discountCode: {} }));
  } finally {
    dispatch(setFullScreenLoader(false));
  }
};

export const getCities = (showLoader, citiesUpdatedAt) => async (dispatch) => {
  try {
    if (showLoader) dispatch(setFullScreenLoader(true));
    const res = await request("/cities", "get", false, null, { updatedAt: citiesUpdatedAt });
    const { cities: citiesList, updatedAt } = res.data.data;

    // localStorage.setItem("cities", JSON.stringify(res.data.data.cities));
    // const citiesList = JSON.parse(localStorage.getItem("cities"));
    if (citiesList.length) {
      const cities = {
        'Punjab': [],
        'Sindh': [],
        'Khyber Pakhtunkhwa': [],
        'Islamabad': [],
        'Balochistan': [],
        'Azad Kashmir': [],
        'Gilgit-Baltistan': [],
      };
      for (let i = 0; i < citiesList.length; i++) {
        cities[citiesList[i].province].push(citiesList[i]);
      }
      cities['Punjab'].sort((a, b) => (a.city > b.city) ? 1 : ((b.city > a.city) ? -1 : 0));
      cities['Sindh'].sort((a, b) => (a.city > b.city) ? 1 : ((b.city > a.city) ? -1 : 0));
      cities['Khyber Pakhtunkhwa'].sort((a, b) => (a.city > b.city) ? 1 : ((b.city > a.city) ? -1 : 0));
      cities['Islamabad'].sort((a, b) => (a.city > b.city) ? 1 : ((b.city > a.city) ? -1 : 0));
      cities['Balochistan'].sort((a, b) => (a.city > b.city) ? 1 : ((b.city > a.city) ? -1 : 0));
      cities['Azad Kashmir'].sort((a, b) => (a.city > b.city) ? 1 : ((b.city > a.city) ? -1 : 0));
      cities['Gilgit-Baltistan'].sort((a, b) => (a.city > b.city) ? 1 : ((b.city > a.city) ? -1 : 0));
      dispatch(setCities({ cities, updatedAt }));
    }
  } catch (e) {
    console.log("error => ", e);
  } finally {
    if (showLoader) dispatch(setFullScreenLoader(false));
  }
};

import actionTypes from './Auth.types';

const initialState = {
  user: {},
  fullScreenLoading: false
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        user: payload
      };
    case actionTypes.SET_FULL_SCREEN_LOADER:
      return { ...state, fullScreenLoader: payload };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
};

export default reducer;
import actionTypes from "./Cart.types";

export const setCities = (payload) => ({
  payload,
  type: actionTypes.SET_CITIES,
});

export const setCartItems = (payload) => ({
  payload,
  type: actionTypes.SET_ITEMS,
});

export const addCartItem = (payload) => ({
  payload,
  type: actionTypes.ADD_ITEM,
});

export const removeCartItem = (payload) => ({
  payload,
  type: actionTypes.REMOVE_ITEM,
});

export const increaseCartItemQuantity = (payload) => ({
  payload,
  type: actionTypes.INCREASE_ITEM_QUANTITY,
});

export const decreaseCartItemQuantity = (payload) => ({
  payload,
  type: actionTypes.DECREASE_ITEM_QUANTITY,
});

export const setShippingInfo = payload => ({
  payload,
  type: actionTypes.SET_SHIPPING_INFO,
});

export const setShippingUser = payload => ({
  payload,
  type: actionTypes.SET_SHIPPING_USER,
});

export const setShippingCost = payload => ({
  payload,
  type: actionTypes.SET_SHIPPING_COST,
});

import actionTypes from "./Reviews.types";

const initialState = {
  reviews: {
    pending: [],
    reviewed: [],
  },
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_REVIEWS:
      return { ...state, reviews: payload };
    case actionTypes.SET_REVIEWED:
      return {
        ...state,
        reviews: {
          pending: state.reviews.pending.filter(r => r.id !== payload.id),
          reviewed: [payload, ...state.reviews.reviewed],
        }
      };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
};

export default reducer;

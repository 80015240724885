import React from "react";
import { hydrate, render } from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import firebase from "firebase/app";
import 'firebase/database';
// import 'firebase/auth';
// import 'firebase/functions';
import config from './config/firebaseConfig';
import { store, persistor } from "./store";
import isDevMode from "./shared/isDevMode";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ReactPixel from 'react-facebook-pixel';

// Initialize the Facebook Pixel with your Pixel ID
ReactPixel.init('1784837755312779');

// Set up additional configurations if needed
ReactPixel.pageView();

firebase.initializeApp(config);

if (isDevMode()) {
  firebase.functions().useEmulator("localhost", 5001);
  firebase.auth().useEmulator("http://localhost:9099");
  firebase.database().useEmulator("localhost", 9000);
}


const rootElement = document.getElementById("root");
const app = <React.Fragment>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
</React.Fragment>;

if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement);
} else {
  render(app, rootElement);
}

serviceWorkerRegistration.unregister();
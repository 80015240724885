import React, { lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Spinner from "../components/Spinner/Spinner";
import PrivateRoute from "./PrivateRoute";

// Static UI screens
const HomePage = lazy(() => import("../containers/HomePage"));
const InteractiveHome = lazy(() => import("../containers/InteractiveHome"));
const MoodControlledLightening = lazy(() =>
  import("../containers/MoodControlledLightening")
);
// const AutomaticGates = lazy(() => import("../containers/AutomaticGates"));
// const OtherProducts = lazy(() => import("../containers/OtherProducts"));
// const KeylessEntry = lazy(() => import("../containers/KeylessEntry"));
// const SecurityAndSurveillance = lazy(() =>
//   import("../containers/SecurityAndSurveillance")
// );
// const SolarEnergy = lazy(() => import("../containers/SolarEnergy"));
// const WaterEnergyManagement = lazy(() =>
//   import("../containers/WaterEnergyManagement")
// );

// Shopping screens
const ShopPage = lazy(() => import("../containers/ShopPage"));
const ProductPage = lazy(() => import("../containers/ProductPage"));
const CartPage = lazy(() => import("../containers/CartPage"));
const CustomerInfo = lazy(() =>
  import("../containers/CustomerInfo/CustomerInfo")
);
const PaymentMethod = lazy(() =>
  import("../containers/PaymentMethod/PaymentMethod")
);
const OrderPlaced = lazy(() => import("../components/OrderPlaced/OrderPlaced"));
const OrderFailed = lazy(() => import("../components/OrderFailed/OrderFailed"));

// Site info screens
const AboutUs = lazy(() => import("../containers/AboutUs/AboutUs"));
const ContactUs = lazy(() => import("../containers/ContactUs/ContactUs"));
const TermsOfService = lazy(() => import("../containers/TermsOfService"));
const PrivacyPolicy = lazy(() => import("../containers/PrivacyPolicy"));
// const Blogs = lazy(() => import("../containers/Blogs/Blogs"));
// const Blog = lazy(() => import("../containers/Blog/Blog"));

// User specific private screens
const Favourites = lazy(() => import("../containers/Favourites/Favourites"));
const Orders = lazy(() => import("../containers/Orders/Orders"));
const OrderDetails = lazy(() => import("../containers/OrderDetails/OrderDetails"));
const Reviews = lazy(() => import("../containers/Reviews/Reviews"));
const WriteReview = lazy(() => import("../containers/WriteReview/WriteReview"));
const MyAccount = lazy(() => import("../containers/MyAccount/MyAccount"));

const getRoutesJSX = () => {
  const routes = (
    <Switch>
      {/* Static UI screens */}
      <Route path="/" component={HomePage} exact />
      <Route path="/interactive-home" component={InteractiveHome} />
      <Route
        path="/mood-controlled-lighting"
        component={MoodControlledLightening}
      />
      {/* <Route path="/keyless-entry" component={KeylessEntry} />
      <Route
        path="/water-energy-management"
        component={WaterEnergyManagement}
      />
      <Route path="/automatic-gates" component={AutomaticGates} />
      <Route
        path="/security-and-surveillance"
        component={SecurityAndSurveillance}
      />
      <Route path="/solar-energy" component={SolarEnergy} />
      <Route path="/other-products" component={OtherProducts} /> */}

      {/* Shopping screens */}
      <Route path="/shop/:key" component={ShopPage} />
      <Route path="/product/:key" component={ProductPage} />
      <Route path="/cart/" component={CartPage} />
      <Route path="/customer-info/" component={CustomerInfo} />
      <Route path="/payment-method/:paymentMethod/:orderIdentifier" component={PaymentMethod} />
      <Route path="/payment-method/:paymentMethod" component={PaymentMethod} />
      <Route path="/order-placed/:orderId" component={OrderPlaced} />
      <Route path="/order-failed/" component={OrderFailed} />

      {/* Site info screens */}
      <Route path="/about-us" component={AboutUs} />
      <Route path="/contact-us" component={ContactUs} />
      <Route path="/terms-of-service" component={TermsOfService} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      {/* <Route path="/blogs/:topic" component={Blogs} />
      <Route path="/blog/:id" component={Blog} /> */}

      {/* User specific private screens */}
      <PrivateRoute path="/orders/:type" component={Orders} />
      <PrivateRoute path="/order-details/:id" component={OrderDetails} />
      <PrivateRoute path="/favourites/" component={Favourites} />
      <PrivateRoute path="/account" component={MyAccount} />
      <PrivateRoute path="/reviews/:type" component={Reviews} />
      <PrivateRoute path="/write-review" component={WriteReview} />

      <Redirect from="/privary-policy" to="/privacy-policy" />
      <Redirect to="/" />
    </Switch>
  );
  return <Suspense fallback={<Spinner />}>{routes}</Suspense>;
};

export const hiddenRoutesForSEO = [
  "/interactive-home",
  "/mood-controlled-lighting",
  // "/keyless-entry",
  // "/water-energy-management",
  // "/security-and-surveillance",
  // "/automatic-gates",
  // "/solar-energy",
  // "/other-products",
]

export default getRoutesJSX;

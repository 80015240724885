import actionTypes from './Auth.types';

export const setUser = user => ({
  'payload': user,
  'type': actionTypes.SET_USER
});

export const setFullScreenLoader = (data) => ({
  payload: data,
  type: actionTypes.SET_FULL_SCREEN_LOADER,
});

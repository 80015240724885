import React, { useEffect } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import getRoutesJSX, { hiddenRoutesForSEO } from "./shared/routes";
import "./App.scss";
// import firebase from "firebase/app";
// import "firebase/storage";
import { useDispatch, useSelector } from "react-redux";
import FullScreenLoader from "./components/FullScreenLoader/FullScreenLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  clearCart,
  getCities,
} from "./containers/CartPage/Cart.service";
import { logout } from "./components/AuthModal/Auth.service";

const App = () => {
  const dispatch = useDispatch();
  const citiesUpdatedAt = useSelector(state => state.cart.citiesUpdatedAt)

  useEffect(() => {
    dispatch(getCities(false, citiesUpdatedAt));

    // Create a reference to the file whose metadata we want to change
    // const folderName = "/assets/home-page/landing";
    // var storageRef = firebase.storage().ref(folderName);
    // console.log("1")
    // storageRef.listAll().then(function (result) {
    //   console.log("2", result.items)
    //   result.items.forEach(function (imageRef) {
    //     console.log("3")
    //     var forestRef = firebase.storage().ref(`/${folderName}/${imageRef.name}`);

    //     // Create file metadata to update
    //     var newMetadata = {
    //       cacheControl: 'public,max-age=2592000',
    //     };

    //     // Update metadata properties
    //     forestRef.updateMetadata(newMetadata)
    //       .then((metadata) => {
    //         // Updated metadata for 'images/forest.jpg' is returned in the Promise
    //         console.log(metadata)
    //       }).catch((error) => {
    //         // Uh-oh, an error occurred!
    //         console.log(error)
    //       });
    //   });
    // }).catch(function (error) {
    //   // Handle any errors
    //   console.log("error", error)
    // });

    window.addEventListener('storage', () => {
      setTimeout(() => {
        if (!localStorage.getItem("pintar-token") || !localStorage.getItem("pintar-refresh-token")) {
          dispatch(logout());
        }
        if (localStorage.getItem("clear-cart")) {
          dispatch(clearCart());
          setTimeout(() => {
            localStorage.removeItem("clear-cart");
          }, 500);
        }
      }, 500);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // return <div />

  return <Router>
    {hiddenRoutesForSEO.map(r => <Link key={r} to={r} />)}
    <FullScreenLoader />
    <ToastContainer hideProgressBar={true} />
    <div className="App">{getRoutesJSX()}</div>
  </Router>;
};

export default App;

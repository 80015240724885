import actionTypes from "./Favourites.types";

const initialState = {
  favourites: {
    available: [],
    outOfStock: [],
  }
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_FAVOURITES:
      return { ...state, favourites: payload };
    case actionTypes.ADD_FAVOURITE:
      return { ...state, favourites: { ...state.favourites, [payload.type]: state.favourites[payload.type].concat(payload) } };
    case actionTypes.REMOVE_FAVOURITE:
      return {
        ...state,
        favourites: {
          available: state.favourites.available.filter(p => p.id !== payload),
          outOfStock: state.favourites.outOfStock.filter(p => p.id !== payload)
        }
      };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
};

export default reducer;

import actionTypes from './Home.types';

const initialState = {
  bestSelling: [],
  hotSelling: [],
  newLaunching: [],
  headerRibbon: '',
  saleBanner: '',
  updatedAt: '',
  banners: {
    desktop: [],
    mobile: [],
  }
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_BEST_SELLING:
      return { ...state, bestSelling: payload.products, updatedAt: payload.updatedAt };
    case actionTypes.SET_HOT_SELLING:
      return { ...state, hotSelling: payload.products };
    case actionTypes.SET_NEW_LAUNCHING:
      return { ...state, newLaunching: payload.products };
    case actionTypes.SET_NEW_LAUNCHING:
      return { ...state, newLaunching: payload.products };
    case actionTypes.SET_HEADER_RIBBON:
      return { ...state, headerRibbon: payload };
    case actionTypes.SET_SALE_BANNER:
      return { ...state, saleBanner: payload };
    case actionTypes.SET_BANNERS:
      return { ...state, banners: payload };
    default:
      return state;
  }
};

export default reducer;

import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import AuthReducer from "../components/AuthModal/Auth.reducer";
import ShopReducer from "../containers/ShopPage/Shop.reducer";
import ProductReducer from "../containers/ProductPage/Product.reducer";
import CartReducer from "../containers/CartPage/Cart.reducer";
import OrdersReducer from "../containers/Orders/Orders.reducer";
import FavouritesReducer from "../containers/Favourites/Favourites.reducer";
import HomeReducer from "../containers/HomePage/Home.reducer";
import ReviewsReducer from "../containers/Reviews/Reviews.reducer";
import BlogsReducer from "../containers/Blogs/Blogs.reducer";

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  auth: AuthReducer,
  shop: ShopReducer,
  cart: CartReducer,
  orders: OrdersReducer,
  product: ProductReducer,
  favourites: FavouritesReducer,
  home: HomeReducer,
  reviews: ReviewsReducer,
  blogs: BlogsReducer,
});

export default persistReducer(persistConfig, rootReducer);

export default {
  SET_ITEMS: "SET_ITEMS",
  SET_CITIES: "SET_CITIES",
  ADD_ITEM: "ADD_ITEM",
  REMOVE_ITEM: "REMOVE_ITEM",
  INCREASE_ITEM_QUANTITY: "INCREASE_ITEM_QUANTITY",
  DECREASE_ITEM_QUANTITY: "DECREASE_ITEM_QUANTITY",
  SET_SHIPPING_INFO: "SET_SHIPPING_INFO",
  SET_SHIPPING_USER: "SET_SHIPPING_USER",
  CLEAR_CART: "CLEAR_CART",
  CLEAR_CITIES: "CLEAR_CITIES",
  SET_SHIPPING_COST: "SET_SHIPPING_COST",
};

const firebaseConfig = {
    apiKey: "AIzaSyC2qAJkE2qdkgna-4R0LeM_etii-2EvpZ4",
    authDomain: "pintar-774c3.firebaseapp.com",
    databaseURL: "https://pintar-774c3-default-rtdb.firebaseio.com",
    projectId: "pintar-774c3",
    storageBucket: "pintar-774c3.appspot.com",
    messagingSenderId: "218357965421",
    appId: "1:218357965421:web:801187a46c334e0a4a1564",
    measurementId: "G-79CPBY0CKY"
  };

  export default firebaseConfig;
import axios from "axios";
import isDevMode from "./isDevMode";

const parseJwt = token => { // This parses your token and provides a JS object with data
  // eslint-disable-next-line prefer-destructuring
  const base64Url = token.split('.')[1];
  // eslint-disable-next-line require-unicode-regexp
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64)
    .split('')
    .map(c => {
      return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
    })
    .join(''));
  return JSON.parse(jsonPayload);
};

let refreshPromise = null;

const fetchToken = async () => {
  const allTokens = fetchAllTokens();
  const token = allTokens && allTokens.accessToken;
  if (!token) {
    return null;
  }
  const diff = parseJwt(token).exp - Math.round(new Date().getTime() / 1000);
  
  if (diff > 10) {
    // If more than 10 seconds are left for token to expire;
    return token;
  }
  if (refreshPromise !== null) {
    await refreshPromise;
    return fetchToken();
  }
  refreshPromise = axios.post(`https://securetoken.googleapis.com/v1/token?key=AIzaSyC2qAJkE2qdkgna-4R0LeM_etii-2EvpZ4`, { grant_type: "refresh_token", refresh_token: allTokens.refreshToken })
    .then(res => {
      const { refresh_token, id_token } = res.data;
      localStorage.setItem("pintar-token", id_token);
      localStorage.setItem("pintar-refresh-token", refresh_token);
      refreshPromise = null;
    })
    .catch(err => {
      console.log(err);
      localStorage.removeItem('pintar-token');
      localStorage.removeItem('pintar-refresh-token');
      window.location.reload()
    })
  await refreshPromise;
  return fetchToken();
};

const fetchAllTokens = () => {
  const refreshToken = localStorage.getItem('pintar-refresh-token');
  const accessToken = localStorage.getItem('pintar-token');
  return { accessToken, refreshToken };
};

const request = async (route, method, headers, data, params) => {
  let API_ENDPOINT = "https://asia-south1-pintar-774c3.cloudfunctions.net";
  if (isDevMode()) {
    API_ENDPOINT = "http://localhost:5001/pintar-774c3/asia-south1";
  }

  const request = {
    method,
    'url': API_ENDPOINT + route
  };

  if (method !== 'get') {
    request.data = data;
  }

  if (params) {
    request.params = params;
  }

  if (headers) {
    const token = await fetchToken();
    request.headers = {
      ...request.headers,
      'Authorization': token,
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
  }
  return axios(request);
};

export default request;
import actionTypes from "./Shop.types";

const initialState = {
  categories: {},
  tags: [],
  types: [],
  priceRange: {},
  updatedAt: ''
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_PRODUCTS_IN_CATEGORY:
      return {
        ...state,
        categories: {
          ...state.categories,
          [payload.id]: payload.products,
        },
      };
    case actionTypes.SET_ALL_FILTERS:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default reducer;
